import React from 'react'
import Umass from '../../images/umass.png'
import AwesomeSlider from 'react-awesome-slider';
import p1 from '../../images/me-myself-and-I/p1.png';
import p2 from '../../images/me-myself-and-I/p2.png';
import p3 from '../../images/me-myself-and-I/p3.png';
import p4 from '../../images/me-myself-and-I/p4.png';
import p9 from '../../images/me-myself-and-I/p9.png';



import 'react-awesome-slider/dist/styles.css';

class Slider extends React.Component {
    state = {
        items: [
            {id: 1, src: Umass},
            {id: 2, src: p1},
            {id: 3, src: p2},
            {id: 4, src: p3},
            {id: 5, src: p4},
            {id: 6, src: p9}
        ]
    }
    render(){
        const { items } = this.state;
        return (
        <AwesomeSlider>
            {items.map(item => <div key={item.id} data-src={item.src}></div>)}
            {/* <div data-src={Desert}/>
            <div data-src={Umass} />
            <div data-src={Umass} /> */}
        </AwesomeSlider>
    )
    }
};

export default Slider;